<template>
 <div>
    <v-row no-gutters class="pa-2">
        <v-col cols="12">
          <v-card v-if="!isOnline" flat class="text-center pa-4">
            
            <p style="margin-top: 120px;">
              <v-btn  x-large fab><v-icon>mdi-wifi-off</v-icon></v-btn>
            </p>
              Please connect to the Internet 
          </v-card>
          <v-card v-else>
            
                <v-row dense no-gutters class="pa-2"> 
                </v-row>
                <div id="PrintReport">
                  <table class="print_table3 md-font" style="width: 100%;">
                     
                      <tr>
                          <td><v-icon left color="secondary" small>mdi-card-account-details</v-icon> Account  #:</td>
                          <td>{{account.id}}</td>
                      </tr>
                        <!-- <tr>
                          <td><v-icon left color="secondary" small>mdi-database</v-icon>Balance:</td>
                          <td>{{$money(account.balance)}}</td>
                      </tr> -->
                        <tr>
                          <td><v-icon left color="secondary" small>mdi-shield-account</v-icon>Username:</td>
                          <td>{{account.username}}</td>
                      </tr>
                      <tr>
                          <td><v-icon left color="secondary" small>mdi-card-account-details-outline</v-icon>Fullname</td>
                          <td>{{account.fullname}}</td>
                      </tr>
                       <tr>
                          <td><v-icon left color="secondary" small>mdi-card-account-details</v-icon> Account Type:</td>
                          <td>{{account.role}}</td>
                      </tr>
                      <tr v-if="account.role != 'Player'">
                          <td><v-icon left color="secondary" small>mdi-percent</v-icon> Set Commission:</td>
                          <td>{{account.commission}}%</td>
                      </tr>
                       <tr>
                          <td><v-icon left color="secondary" small>mdi-cellphone-basic</v-icon>Contact No.</td>
                          <td>{{account.contact_no}}</td>
                      </tr>
                      <tr>
                          <td><v-icon left color="secondary" small>mdi-wallet</v-icon>Balance</td>
                          <td>{{$money(account.balance)}}
                            
                          </td>
                      </tr>
                       <tr>
                          <td><v-icon left color="secondary" small>mdi-wallet</v-icon>Commission</td>
                          <td>{{$money(account.comm_amount)}}
                            <v-btn style="float: right;" x-small color="accent" @click="conform=true">Convert</v-btn>
                          </td>
                      </tr>
                      <tr>
                          <td><v-icon left color="secondary" small>mdi-key-change</v-icon>Password</td>
                          <td>*** <v-icon right color="warning" small @click="passform=true">mdi-pencil</v-icon></td>
                      </tr>
                       <tr>
                       
                          <!-- <td colspan="2" align="center">
                            <span> Referral Link </span><br/>
                             <span class="text-info"> https://juego.live/register/{{account.id}}</span></td> -->
                      </tr>
                  </table>
                </div>
            </v-card>
        </v-col>
    </v-row>
    <va-password-form :show="passform" @DialogEvent="pasEvent"/>
    <va-convert-form :show="conform" :account="account" @DialogEvent="pasEvent"/>
</div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
      passform: false,
      conform: false,
        search:'',
        from_date:'',
        to_date: '',
        date_range:"",
        account:{},
        transactions:[],
        per_type:'Overall'
    }),
    created() {
      this.PING()
     this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
      // this.userDetails()
    },
     mounted(){
       this.setLoggedIn(true)
        if (this.user == null) {
          this.$router.push('/')
        }else {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
          this.userDetails()
        }
    },
    computed:{
      isOnline(){
        return this.$store.state.is_online
      },
      user() {
        if(localStorage.user){
            return JSON.parse(localStorage.user)
        }
        return null
      },
     
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn', 'setOverlayLoading']),
      pasEvent(){
        this.passform = false
        this.conform = false
        this.userDetails()
      },

     userDetails(){
       console.log(this.user)
          this.$http.post("user/details",{id: this.user.id}).then(response => {
            console.log(response.data.account)
              if(response.data.status) {
                 this.account = response.data.account
              } 
          }).catch(e => {
            console.log(e.data)
          })
    },
    },
  }
</script>